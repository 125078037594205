import Easing from 'utils/easing'

/*
 * Animates the page scroll to a specified y value using an ease-out.
 * @param {number} y The y value to scroll to.
 */
export default function scrollTo(y, duration) {
  const ease = new Easing({
    duration,
    startValue: window.pageYOffset || document.documentElement.scrollTop,
    endValue: y,
    updated: (value) => {
      window.scrollTo(0, value)
    },
  })

  ease.start()
}
