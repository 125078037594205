/*
 * Calculate delay of elements passed as keys
 *
 * @param keys Array[String]
 * return Object
 */
function calculateDelay(keys) {
  const obj = {}

  const baseDelay = 1000
  const delayIncrease = 100
  let calculatedDelay = baseDelay
  // Iterate through keys passed as argument
  Object.keys(keys).forEach((key, index) => {
    // If value is not falsy
    if (keys[key]) {
      // Calculate delay for current item
      obj[key] = `${(calculatedDelay - baseDelay) / 1000}s`
      calculatedDelay += delayIncrease - index / delayIncrease
    }
  })
  // Return obj of delays for each item
  return obj
}

export default {
  calculateDelay,
}
