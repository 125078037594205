export default class Easing {
  /**
   * Easing provides the ability to tween between two values using an easing
   * curve, firing a callback on each tick of the tween.
   *
   * @param {Object} params Params passed in as a single object.
   * @param {number} params.startValue The value to ease from.
   * @param {number} params.endValue The value to ease to.
   * @param {Function} params.finished The callback fired after the last update.
          Gets passed the endValue as a parameter.
   * @param {Function} params.updated The callback fired on every update. Gets
   *     passed the updated value as a parameter.
   * @param {?number} params.duration The duration of the ease.
   * @param {?string} params.curve The name of the easing curve to use,
   *     e.g. 'easeOut'.
   * @constructor
   */
  constructor({
    curve = 'easeOut',
    duration = 1000,
    endValue = 0,
    finished = () => {},
    startValue = 0,
    updated = null,
  }) {
    Object.assign(this, {
      curve,
      duration,
      endValue,
      finished,
      startValue,
      updated,
    })

    this.delta = this.endValue - this.startValue
    this.increasing = this.endValue > this.startValue
  }

  /**
   * Robert Penner's easeOutQuart function to incrementally slow the count.
   * @param {number} t Current time.
   * @param {number} b Beginning value.
   * @param {number} c Change in value.
   * @param {number} d Duration.
   * @return {number} The incremented value.
   */
  // eslint-disable-next-line class-methods-use-this
  easeOut(t, b, c, d) {
    // eslint-disable-next-line no-return-assign, no-param-reassign
    return -c * ((t = t / d - 1) * t * t * t - 1) + b
  }

  /**
   * Increments the easeValue data prop recursively until target value is met.
   * @param {number} timestamp Timestamp for when this method is called.
   */
  increment(timestamp) {
    if (!this.startTime) {
      this.startTime = timestamp
    }

    const progress = timestamp - this.startTime

    let frameVal = this[this.curve](
      progress,
      this.startValue,
      this.delta,
      this.duration
    )

    if (this.hasDecimal) {
      frameVal = Math.round(frameVal * 10) / 10
    } else {
      frameVal = Math.round(frameVal)
    }

    // Check if endValue is met, or if duration is exceeded. Duration can be
    // exceeded if the tab loses focus while increment is still in progress,
    // because the next requestAnimationFrame doesn't fire until tab focus
    // is restored.
    const valueReached = this.increasing
      ? frameVal > this.endValue
      : frameVal < this.endValue
    const timeReached = progress >= this.duration

    if (valueReached || timeReached) {
      this.updated(this.endValue)
      this.finished(this.endValue)
    } else {
      this.updated(frameVal)

      if (progress < this.duration) {
        requestAnimationFrame(this.increment.bind(this))
      }
    }
  }

  /**
   * Begins incrementing via native requestAnimationFrame.
   */
  start() {
    requestAnimationFrame(this.increment.bind(this))
  }
}
