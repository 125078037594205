import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from 'components/Layout'
import OpeningTimes from 'components/OpeningTimes'
import { Container, Spacing50 } from 'styles/sharedStyle'
import theme from 'styles/theme'
import openingHoursUtils from 'utils/openingHours'

import Hero from 'components/Hero'

function VillageOpeningHoursPage({ data, pageContext }) {
  const {
    page: {
      village: { openingHours: villageOpeningHours },
      village,
      jsonLdSchema,
    },
    openingHoursLabels,
    openingHoursExceptions,
  } = data

  const { pageLevel } = pageContext

  const { hero } = villageOpeningHours
  const media = hero && hero.media && hero.media[0]
  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)
  const heroImage = hasHeroImage
    ? {
        portrait: media.portrait || media.videoPlaceholder.portrait,
        landscape: media.landscape || media.videoPlaceholder.landscape,
        altText:
          media.altText ||
          (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
      }
    : {}
  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        opacity: media.opacity,
      }
    : {}
  const heroProps = hero
    ? {
        eyebrow: hero.eyebrow,
        content: hero.headline,
        image: heroImage,
        video: heroVideo,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
      }
    : null

  const globalLabels = openingHoursLabels.edges[0].node
  const villageExceptions = openingHoursExceptions.exceptions.reduce(
    openingHoursUtils.reduceExceptions,
    {}
  )

  const villageHours = openingHoursUtils.getWeeklyOpeningHours(
    villageOpeningHours,
    globalLabels,
    {
      numberOfWeeks: 3,
      villageExceptions,
    }
  )

  return (
    <Layout>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>

      {heroProps && <Hero {...heroProps} />}

      <Spacing50 />

      <Container px={[theme.space[4], '0']} maxWidth={[theme.maxWidths[0]]}>
        {villageHours.map((hours, index) => (
          <OpeningTimes
            conditionsCopy={
              villageOpeningHours.footNoteCopy &&
              villageOpeningHours.footNoteCopy.footNoteCopy
            }
            key={`week_${index.toString()}`}
            times={hours}
            title={openingHoursUtils.getWeekLabel(globalLabels, index)}
          />
        ))}
      </Container>
    </Layout>
  )
}

VillageOpeningHoursPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.shape({
    pageLevel: PropTypes.string,
  }).isRequired,
}

export const villageOpeningHoursPageQuery = graphql`
  query(
    $dateEnd: Date!
    $dateStart: Date!
    $nodeLocale: String!
    $openingHoursId: String!
    $pageTypeSlug: String
    $villageLandingPageId: String!
    $villageSlug: String
  ) {
    page: contentfulPageTemplateHomeT01(id: { eq: $villageLandingPageId }) {
      jsonLdSchema {
        jsonLdSchema
      }
      village {
        name
        villageSlug: slug
        currency
        home: page_template_home_t01 {
          ...headerFooterVillage
        }
        openingHours {
          ...villageOpeningHours
          pageTitle
          pageDescription
          hideFromSearchEngine
          hero {
            eyebrow
            headline
            ...heroMediaQuery
          }
        }
        openingStatusLabel
        defaultLocale
      }
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          date(formatString: "DD-MM-YYYY")
          openingTime
          closingTime
          closedFlag
          label
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }
    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
  }
`

export default VillageOpeningHoursPage
