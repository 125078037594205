/* eslint-disable no-plusplus */
// @ts-nocheck

import { useState, useEffect } from 'react'

const componentsCount = {}

/**
 * Gets the number of instances of a given element
 * on the current page
 * @param {*} componentName
 */
const getComponentCount = (componentName) => {
  let key = componentName

  if (typeof window !== `undefined`) {
    key = `${window.location.pathname.split('/').join('-')}-${componentName}`
  }

  componentsCount[key] = componentsCount[key] ? componentsCount[key] + 1 : 1
  return componentsCount[key]
}

/**
 * Example: Comp_hero_HER01-3
 * @param {*} componentName
 */
const getComponentId = (componentName) =>
  `${componentName}-${getComponentCount(componentName)}`

/**
 * Assigns a temporary ID (build time) and updates it
 * on page load
 * @param {*} componentName
 */
const useComponentId = (componentName) => {
  const [uniqueId, setUniqueId] = useState(componentName)

  useEffect(() => {
    const newId = getComponentId(componentName)
    setUniqueId(newId)
  }, [])

  return uniqueId
}

/**
 * Example: Comp_hero_HER01-3--image
 * @param {*} componentId
 * @param {*} fieldName
 */
const useFieldId = (componentId, fieldName) =>
  componentId
    ? `${componentId}--${fieldName
        .split(' ')
        .join('_')
        .toLowerCase()}`
    : ''

export { getComponentId, useComponentId, useFieldId }
